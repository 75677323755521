import React from 'react';
import {P} from "../components/Typography"

function WithTableLoading(Component) {
  return function WihLoadingComponent({ isLoading, ...props }) {
    if (!isLoading) return <Component {...props} />;
    return (
      <P context="smalltext">
        chargement du tableau...
      </P>
    );
  };
}
export default WithTableLoading;