import React from 'react';
const GTable = (props) => {
  const { sheetData } = props;
  if (!sheetData || sheetData.length === 0) return <p>No sheet data, sorry</p>;
  return (
    <table style={{borderCollapse: 'collapse', margin: '0 auto'}}>
      <tbody>
      {sheetData.sheets[0].data[0].rowData.map((row, i) => (
        <tr key={i}>
          {row.values.map((cell, j) => {

            var textAlign = ''
            var verticalAlign = ''
            var paddingTop = ''
            var paddingRight = ''
            var paddingBottom = ''
            var paddingLeft = ''
            var borderTop = ''
            var borderRight = ''
            var borderBottom = ''
            var borderLeft = ''
            var fontSize = ''
            var fontWeight= ''

            if (cell.effectiveFormat.hasOwnProperty('horizontalAlignment')) {
              textAlign = cell.effectiveFormat.horizontalAlignment
            }
            if (cell.effectiveFormat.hasOwnProperty('veerticalAlignment')) {
              verticalAlign = cell.effectiveFormat.verticalAlignment
            }
            if (cell.effectiveFormat.hasOwnProperty('padding')) {
              paddingTop = cell.effectiveFormat.padding.top + 'px'
              paddingRight = cell.effectiveFormat.padding.right + 'px'
              paddingBottom = cell.effectiveFormat.padding.bottom + 'px'
              paddingLeft = cell.effectiveFormat.padding.left + 'px'
            }
            if (cell.effectiveFormat.hasOwnProperty('borders')) {
              if (cell.effectiveFormat.borders.hasOwnProperty('top')) {
                var borderStyle = ''
                if (cell.effectiveFormat.borders.top.style.includes('DOTTED')){borderStyle = 'dotted'} else {borderStyle = 'solid'}
                if (cell.effectiveFormat.borders.top.color.hasOwnProperty('red')) {
                  borderTop = cell.effectiveFormat.borders.top.width + 'px ' + borderStyle + ` rgb(${cell.effectiveFormat.borders.top.color.red * 100}%, ${cell.effectiveFormat.borders.top.green * 100}%, ${cell.effectiveFormat.borders.top.blue * 100}%)`
                } else {
                  borderTop = cell.effectiveFormat.borders.top.width + 'px ' + borderStyle + ' #181818'
                }
              }
              if (cell.effectiveFormat.borders.hasOwnProperty('right')) {
                var borderStyle = ''
                if (cell.effectiveFormat.borders.right.style.includes('DOTTED')){borderStyle = 'dotted'} else {borderStyle = 'solid'}
                if (cell.effectiveFormat.borders.right.color.hasOwnProperty('red')) {
                  borderRight = cell.effectiveFormat.borders.borderRight.width + 'px ' + borderStyle + ` rgb(${cell.effectiveFormat.borders.right.color.red * 100}%, ${cell.effectiveFormat.borders.right.color.green * 100}%, ${cell.effectiveFormat.borders.right.color.blue * 100}%)`
                } else {
                  borderRight = cell.effectiveFormat.borders.right.width + 'px ' + borderStyle + ' #181818'
                }
              }
              if (cell.effectiveFormat.borders.hasOwnProperty('bottom')) {
                var borderStyle = ''
                if (cell.effectiveFormat.borders.bottom.style.includes('DOTTED')){borderStyle = 'dotted'} else {borderStyle = 'solid'}
                if (cell.effectiveFormat.borders.bottom.color.hasOwnProperty('red')) {
                  borderBottom = cell.effectiveFormat.borders.bottom.width + 'px ' + borderStyle + ` rgb(${cell.effectiveFormat.borders.bottom.color.red * 100}%, ${cell.effectiveFormat.borders.bottom.color.green * 100}%, ${cell.effectiveFormat.borders.bottom.color.blue * 100}%)`
                } else {
                  borderBottom = cell.effectiveFormat.borders.bottom.width + 'px ' + borderStyle + ' #181818'
                }
              }
              if (cell.effectiveFormat.borders.hasOwnProperty('left')) {
                var borderStyle = ''
                if (cell.effectiveFormat.borders.left.style.includes('DOTTED')){borderStyle = 'dotted'} else {borderStyle = 'solid'}
                if (cell.effectiveFormat.borders.left.color.hasOwnProperty('red')) {
                  borderLeft = cell.effectiveFormat.borders.left.width + 'px ' + borderStyle + ` rgb(${cell.effectiveFormat.borders.left.color.red * 100}%, ${cell.effectiveFormat.borders.left.color.green * 100}%, ${cell.effectiveFormat.borders.left.color.blue * 100}%)`
                } else {
                  borderLeft = cell.effectiveFormat.borders.left.width + 'px ' + borderStyle + ' #181818'
                }
              }
            }
            if (cell.effectiveFormat.hasOwnProperty('textFormat')) {
              if (cell.effectiveFormat.textFormat.hasOwnProperty('fontSize')) {
                fontSize = cell.effectiveFormat.textFormat.fontSize + 'px'
              }
              if (cell.effectiveFormat.textFormat.bold === true) {
                fontWeight = '600'
              }
            }

            const tdStyle = {
              textAlign: textAlign,
              verticalAlign: verticalAlign,
              paddingTop: paddingTop,
              paddingRight: paddingRight,
              paddingBottom: paddingBottom,
              paddingLeft: paddingLeft,
              borderTop: borderTop,
              borderRight: borderRight,
              borderBottom: borderBottom,
              borderLeft: borderLeft,
              fontSize: fontSize,
              fontWeight: fontWeight
            }

            var rowspan = null
            var colspan = null
            if (sheetData.sheets[0].hasOwnProperty('merges')) {
              for (var k = 0; k < sheetData.sheets[0].merges.length; k++) {
                const startRowIndex = sheetData.sheets[0].merges[k].startRowIndex - sheetData.sheets[0].data[0].startRow
                const endRowIndex = sheetData.sheets[0].merges[k].endRowIndex - sheetData.sheets[0].data[0].startRow
                const startColumnIndex = sheetData.sheets[0].merges[k].startColumnIndex - sheetData.sheets[0].data[0].startColumn
                const endColumnIndex = sheetData.sheets[0].merges[k].endColumnIndex - sheetData.sheets[0].data[0].startColumn
                if (i === startRowIndex && j === startColumnIndex) {
                  rowspan = endRowIndex - startRowIndex
                  colspan = endColumnIndex - startColumnIndex
                } 
                else if (i < endRowIndex && i >= startRowIndex && (j < endColumnIndex && j >= startColumnIndex)) {
                  return null
                }
              }
            }

            if (cell.effectiveFormat.hasOwnProperty('textFormat')) {
              if (cell.effectiveFormat.textFormat.italic === true) {
                return (
                  <td 
                    style={tdStyle}
                    colSpan={colspan}
                    rowSpan={rowspan}
                    key={j}>
                      <i>{cell.formattedValue}</i>
                  </td>
                )
              } else {
                return (
                  <td 
                    style={tdStyle}
                    colSpan={colspan}
                    rowSpan={rowspan}
                    key={j}>
                      {cell.formattedValue}
                  </td>
                )
              }
            } else {
              return (
                <td 
                  style={tdStyle}
                  colSpan={colspan}
                  rowSpan={rowspan}
                  key={j}>
                    {cell.formattedValue}
                </td>
              )
            }
          })}
        </tr>
      ))}
      </tbody>
    </table>
  );
};
export default GTable;