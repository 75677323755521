import React, { useEffect, useState } from 'react';
import GTable from './GTable';
import withTableLoading from './TableLoading';
import {P} from "./Typography";
import styled from "styled-components"

const PcEnTableApp = styled.div`
  padding-bottom: ${props => props.theme.spacings.xxLarge};
`
const TableTitle = styled(P)`
  padding-bottom: ${props => props.theme.spacings.large};
  padding-top: ${props => props.theme.spacings.xLarge};
`

const TableApp = ({sheetId, apiKey, sheetName, range, title}) => {
  const TableLoading = withTableLoading(GTable);
  const [appState, setAppState] = useState({
    loading: false,
    sheetData: null,
  });

  useEffect(() => {
    setAppState({ loading: true });
    const sheetNameEncoded = encodeURI(sheetName)
    const fullRange = `&ranges=${sheetNameEncoded}!${range}`
    const apiUrl = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/?key=${apiKey}&includeGridData=true${fullRange}`;
    fetch(apiUrl)
      .then((res) => res.json())
      .then((sheetData) => {
        setAppState({ loading: false, sheetData: sheetData });
      });
  }, [setAppState, apiKey, sheetId, range, sheetName]);
  return (
    <PcEnTableApp className='PcEnTableApp'>
      <TableTitle context="smallinfo">&mdash; {title}</TableTitle>
      <div className='table-container' style={{overflow: 'scroll'}}>
        <TableLoading isLoading={appState.loading} sheetData={appState.sheetData} />
      </div>
    </PcEnTableApp>
  );
}
export default TableApp;