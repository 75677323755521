import React, {useState, useRef, useEffect} from "react"
import PortableText from "@sanity/block-content-to-react"
import {graphql, Link} from "gatsby"
import Layout from "../components/layout"
import {H1, H2, H3, H4, P, Li, Caption} from "../components/Typography"
import {GatsbyImage} from 'gatsby-plugin-image'
import styled from "styled-components"
import urlBuilder from '@sanity/image-url'
import client from './sanityClient'
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official"
import TableApp from "../components/TableApp"
import SEO from "../components/seo"

function flatten(array, mutable) {
  var toString = Object.prototype.toString;
  var arrayTypeStr = '[object Array]';
  
  var result = [];
  var nodes = (mutable && array) || array.slice();
  var node;

  if (!array.length) {
      return result;
  }

  node = nodes.pop();
  
  do {
      if (toString.call(node) === arrayTypeStr) {
          nodes.push.apply(nodes, node);
      } else {
          result.push(node);
      }
  } while (nodes.length && (node = nodes.pop()) !== undefined);

  result.reverse();
  return result;
}

function getBlockNotes (blocks) {
  const blockNotes = []

  blocks.forEach(block => {
    if (block._type === 'block' && block.markDefs) {
      const blockMarks = block.children.map(child => child.marks)

      flatten(blockMarks).forEach(markKey => {
        const note = block.markDefs.find(markDef => markDef._key === markKey && markDef._type === 'footnote')

        if (note) {
          blockNotes.push(note)
        }
      })
    }
  })
  // console.log(blockNotes)
  return blockNotes
}

const EmbedContainer = styled.div`
  grid-column: 1 / span 6;
  width: 100%;
  padding-top: ${props => props.theme.spacings.xxLarge};
  padding-bottom: ${props => props.theme.spacings.largest};

  @media ${props => props.theme.breakpoints.desktop} {
    grid-column: 3 / span 6;
  }
`

const BodyImageContainer = styled.figure`
  grid-column: 1 / span 6;
  padding-top: ${props => props.theme.spacings.xxLarge};
  padding-bottom: ${props => props.theme.spacings.largest};

  @media ${props => props.theme.breakpoints.desktop} {
    grid-column: 3 / span 6;
  }
`

const BodyImage = styled.img`
  width: 100%;
  height: auto;
`

const MetadataList = styled.ul`
  grid-column: 1 / span 3;
  text-align: left;
  grid-row: 4 / span 1;
  list-style: none;
  position: relative;

  @media ${props => props.theme.breakpoints.desktop} {
    grid-column: 1 / span 2;
    grid-row: 3 / span 3;
  }
`

const Date = styled(Li)`
  padding-top: 0;
  padding-bottom: ${props => props.theme.spacings.large};
  text-transform: uppercase;
`

const ContentAuthors = styled.ul`
  list-style: none;
  padding-bottom: ${props => props.theme.spacings.xxLarge};

  @media ${props => props.theme.breakpoints.desktop} {
    padding-top: 0;
    padding-bottom: ${props => props.theme.spacings.large};
  }
`

const ContentType = styled.ul`
    display: none;

    @media ${props => props.theme.breakpoints.desktop} {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-bottom: ${props => props.theme.spacings.large};
      padding-top: 0;
      padding-bottom: 0;
    }
`
const ContentTypeMobile = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: ${props => props.theme.spacings.large};
    grid-column: 4 / span 3;
    grid-row: 4 / span 1;

    @media ${props => props.theme.breakpoints.desktop} {
        display: none;
    }
`

const MetadataElement = styled.div`
    padding-top: ${props => props.theme.spacings.xxSmall};
    padding-bottom: ${props => props.theme.spacings.xxSmall};
    padding-left: ${props => props.theme.spacings.small};
    padding-right: ${props => props.theme.spacings.small};
    display: block;
    border-radius: 5px;
    background-color: ${props => props.theme.colors.gray3};
    margin-right:2px;
    margin-bottom: 2px;
`

const Title = styled(H1)`
  grid-column: 1 / span 6;
  grid-row: 2 / span 1;
  padding-top: ${props => props.theme.spacings.xxLarge};

  @media ${props => props.theme.breakpoints.desktop} {
    grid-column: 3 / span 8;
    grid-row: 3 / span 1;
    padding-top: ${props => props.theme.spacings.xxLarge};
  }
`

const Excerpt = styled(H2)`
  grid-column: 1 / span 6;
  grid-row: 3 / span 1;

  @media ${props => props.theme.breakpoints.desktop} {
    grid-column: 3 / span 7;
    grid-row: 4 / span 1;
  }
`

const EmptySquare = styled.div`
  display: none;

  @media ${props => props.theme.breakpoints.desktop} {
    display: block; 
    position: relative;
    grid-row: 1 / span 1; 
    grid-column: 2 / span 1;
    padding-bottom: 0;
    width: 100%;
    border: 1px solid ${props => props.theme.colors.foreground};
    border-radius: 10px;
    align-self: start;
    transition: border-radius 0.3s;
    cursor: pointer;
    pointer-events: auto;

    :after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    :hover > #actionprompt {
      display: block;
    }
  }
`
const SmallCircle = styled(Link)`
  display: none;

  @media ${props => props.theme.breakpoints.desktop} {
    display: block;
    grid-row: 1 / span 1; 
    grid-column: 1 / span 1;
    position: relative;
    padding-bottom: 0;
    height: 100%;
    width: 100%;
    align-self: start;
    border-radius: 50%;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${props => props.theme.colors.foreground};
    cursor: pointer;
    pointer-events: auto;

    > #backarrow {
      display: block;
    }
  
    :hover .cls-1 {
      fill: ${props => props.theme.colors.background};
    }
    .cls-1 {
      fill: ${props => props.theme.colors.foreground};
    }
  }
`
const ArrowSvg = styled.div`
    width: 20%;
    padding-top: 5px;
    margin-left: auto;
    margin-right: auto;
    transform: scale(-1, 1);
`

const CoverImage = styled.div`
  grid-column: 1 / span 6;
  grid-row: 1 / span 1;
  align-self: start;
  transition: width 0.3s;
  
  > div {
    border-radius: 20px;
  }

  @media ${props => props.theme.breakpoints.desktop} {
    grid-column: 3 / span 1;
    grid-row: 1 / span 2;
    padding-bottom: 0;
    transition: width 0.3s;

    > div {
      border-radius: 10px;
    }
  }
`

const ActionPrompt = styled(P)`
  display: none
  font-family: ${props => props.theme.font.main};
  font-size: 21px;
  font-weight: 300;

  @media ${props => props.theme.breakpoints.desktop} {
    display: none; 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${props => props.theme.colors.foreground};
  }
`

const TextBlock = styled.div`
  grid-column: 1 / span 6;
  grid-row: 5 / span 1;
  overflow: hidden;
  padding-bottom: ${props => props.theme.spacings.largest};

  @media ${props => props.theme.breakpoints.desktop} {
    grid-column: 3 / span 6;
    grid-row: 5 / span 1;
  }
`

const FootnotesBottom = styled.div`
  grid-column: 1 / span 6;
  grid-row: 6 / span 1;
  display: grid;
  grid-template-columns: repeat(6,minmax(0,1fr));
  grid-auto-rows: auto;
  column-gap: calc(6 / 1680 * 100vw)};

  @media ${props => props.theme.breakpoints.desktop} {
    grid-template-columns: repeat(12,minmax(0,1fr));
    column-gap: calc(12 / 1680 * 100vw);
    grid-column: 1 / span 12;
    grid-row: 6 / span 1;
  }
`
const FootnoteSectionTitle = styled.div`
  grid-column: 1;
  grid-row: 1 / span 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: ${props => props.theme.spacings.large};

  @media ${props => props.theme.breakpoints.desktop} {
    grid-column: 3;
    grid-row: 1 / span 1;
  }
`
const FootnoteSectionTitleText = styled(P)`
  padding-top: ${props => props.theme.spacings.xxSmall};
  padding-bottom: ${props => props.theme.spacings.xxSmall};
  padding-left: ${props => props.theme.spacings.xSmall};
  padding-right: ${props => props.theme.spacings.xSmall};
  border-radius: 5px;
  background-color: ${props => props.theme.colors.gray3};
  margin-right:2px;
  margin-bottom: 2px;
`
const FootnoteList = styled.ol`
  grid-column: 2 / span 5;
  grid-row: 2 / span 1;

  @media ${props => props.theme.breakpoints.desktop} {
    grid-column: 3 / span 4;
  }
`

const FootnoteListItem = styled(Li)`
  grid-column: 2 / span 5;

  @media ${props => props.theme.breakpoints.desktop} {
    grid-column: 3 / span 4;
  }
`

const FootnoteListItemBack = styled.a`
  float: left;
  margin-left: -50px;
  font-weight: 200;
  color: ${props => props.theme.colors.gray1};

  @media ${props => props.theme.breakpoints.desktop} {
    float: right;
    margin-right: -28px;

    :hover {
      color: ${props => props.theme.colors.foreground};
    }
  }
`

const Sidenote = styled.aside`
  display: none;

  @media ${props => props.theme.breakpoints.desktop} {
    display: block;
    grid-column: 10 / span 3;
    grid-row: 1 / span all;
    position: absolute;
    top: 0;
    opacity: 0;
    transition: opacity 0.3s;
  }
`

const SidenoteNumber = styled(P)`
  margin-left: -16px;
  float: left;
`

const BodyLink = styled.a`
  color: ${props => props.theme.colors.foreground};
  text-decoration: none;
  border-bottom: 1px solid ${props => props.theme.colors.gray1};

  :hover {
    color: ${props => props.theme.colors.foreground};
    text-decoration: none;
    border-bottom: 1px solid ${props => props.theme.colors.foreground};
  }
`

const BlockUl = styled.ul`
  margin-left: ${props => props.theme.spacings.xLarge};
`
const BlockOl = styled.ol`
  margin-left: ${props => props.theme.spacings.xLarge};
`

const pcenarrow = "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 179.4 154.8'><title>PcEnArrow</title><g id='Layer_2' data-name='Layer 2'><g id='Layer_1-2' data-name='Layer 1'><path class='cls-1' d='M119.4,154.8,109,146.4a275.79,275.79,0,0,1,24.2-30.6A145.53,145.53,0,0,1,157,94.6l-14.4.9q-7.8.5-17,.5H54.2a72.88,72.88,0,0,1-23.3-3.4,48.08,48.08,0,0,1-17-9.6A39.31,39.31,0,0,1,3.5,67.9,54,54,0,0,1,0,48,54,54,0,0,1,3.5,28.1,40.2,40.2,0,0,1,13.9,12.9a46,46,0,0,1,17-9.6A75,75,0,0,1,54.2,0h10V13h-10Q34,13,23.5,22.1T13,48q0,16.61,10.5,25.8T54.2,83h71.4q9.19,0,17,.4t14.4.8A145.53,145.53,0,0,1,133.2,63,275.79,275.79,0,0,1,109,32.4L119.4,24a296.33,296.33,0,0,0,18.9,26.5,186.53,186.53,0,0,0,19.4,20.7,250,250,0,0,0,21.7,17.6V90a216.8,216.8,0,0,0-21.7,17.5,199.8,199.8,0,0,0-19.4,20.7A272.09,272.09,0,0,0,119.4,154.8Z'/></g></g></svg>"

const builder = urlBuilder(client)

const urlFor = source => builder.image(source)

const SinglePost = ({data}) => {
  const post = data.currentpost.edges[0].node
  const image = post.mainImage.asset.gatsbyImageData
  const title = post.title
  const excerpt = post.excerpt
  const date = post.publishedAt
  const authors = post.authors
  const type = post.type
  const sector = post.secteur
  const bodyElements = post._rawBody

  function pinned (postDate) {
    if (postDate === "31-12-3332") {
        const pinned = 'article épinglé'
        return pinned
    } else {
        return postDate
    }
  }

  const allFootnotes = getBlockNotes(bodyElements)

  function footnotesOrder (footnoteKey) {
    const allFootnoteKeys = []
    allFootnotes.map((footnote, i) => (
      allFootnoteKeys.push(footnote._key)
    ));
    
    const footnoteIndex = allFootnoteKeys.indexOf(footnoteKey)
    
    return footnoteIndex + 1
  }  

  const serializer = {
    types: {
      html: props => (
        <EmbedContainer>
          <div dangerouslySetInnerHTML={{__html:props.node.raw_html}}></div>
          <Caption>{props.node.caption}</Caption>
        </EmbedContainer>
      ),
      figure: props => (
        <BodyImageContainer>
          <BodyImage src={urlFor(props.node.image.asset)} width={props.node.image.asset.metadata.dimensions.width} height={props.node.image.asset.metadata.dimensions.height} alt={props.node.alt}/>
          <Caption>{props.node.caption}</Caption>
        </BodyImageContainer>
      ),
      block: props => {
        const style = props.node.style || "normal";

        if (style === "h3") {
          return <H3>{props.children}</H3>;
        } else if (style === "h4") {
          return <H4>{props.children}</H4>;
        } else {
          return <P context="block">{props.children}</P>
        }
      },
      inlineGoogleSheets: props => {
        return (
          <TableApp
            sheetId={props.node.GoogleSpreadsheetId}
            apiKey='AIzaSyCm97Y9NrK0ON5YSadt7y41m7Ya_7w6Uow'
            sheetName={props.node.tabName}
            range={props.node.range}
            title={props.node.tableName}
          />
        )
      },
      inlineChart: props => {
        try {
          const options = JSON.parse(props.node.chartEditor.jsonStr);
        return (<div className="chartArea" style={{paddingTop: "38px", paddingBottom: "21px"}}>
          <P context="smallinfo"  style={{paddingBottom: "21px"}}>&mdash; {props.node.chartTitle}</P>
          <HighchartsReact highcharts={Highcharts} options={options}/>
          </div>
            )
        } catch (e) {
          console.log("Failed to load highcharts options", e);
        }
      },
      inlineImageChart: props => (
        <BodyImageContainer>
          <P context="smallinfo"  style={{paddingBottom: "21px"}}>&mdash; {props.node.chartTitle}</P>
          <BodyImage src={urlFor(props.node.image.asset)} alt={props.node.alt}/>
        </BodyImageContainer>
      )
    },
    list: (props) =>
      props.type === 'bullet' ? (
        <BlockUl>{props.children}</BlockUl>
      ) : (
        <BlockOl>{props.children}</BlockOl>
      ),
    listItem: (props) =>
      props.type === 'bullet' ? (
        <Li context="block">{props.children}</Li>
      ) : (
        <Li context="block">{props.children}</Li>
      ),
    marks: {
      link: props =>
        props.mark.blank ? (
          <BodyLink href={props.mark.href} target='_blank' rel='noopener noreferrer'>{props.children}</BodyLink> 
          ) : (
          <BodyLink href={props.mark.href}>{props.children}</BodyLink>
          ),
      sup: props => (
        <sup>{props.children}</sup>
      ),
      footnote: props => (
        <span>
          {props.children}
          <sup id={"footnote-back-" + props.mark._key} ref={addToFootnoteRefs}>
            <a href={"#footnote-" + props.mark._key} style={{fontWeight: '600', color: '#8d8d8d'}}>{footnotesOrder(props.mark._key)}</a>
          </sup>
        </span>
      ),
      internalFileLink: props => (
          <BodyLink href={props.mark.reference.upfile.asset.url} target='_blank' rel='noopener noreferrer'>{props.children}</BodyLink> 
          )
    }
  }

  const FootnoteSerializer = {
    types: {
      block: props => (
        <P context="smalltext">
          {props.children}
        </P>
      )
    },
    marks: {
      link: props => 
          <BodyLink href={props.mark.href} target='_blank' rel='noopener noreferrer'>{props.children}</BodyLink> 
    }
  }


  const footnoteRefs = useRef([])
  footnoteRefs.current = []

  const addToFootnoteRefs = (el) => {
    if(el && !footnoteRefs.current.includes(el)){
      footnoteRefs.current.push(el)
    }
  }

  const sidenoteRefs = useRef([])
  sidenoteRefs.current = []

  const addToSidenoteRefs = (el) => {
    if(el && !sidenoteRefs.current.includes(el)){
      sidenoteRefs.current.push(el)
    }
  }

  const setSidenotes = () => { 
    var timesRun = 0;
    const interval = setInterval(() => {
      timesRun += 1;
      if(timesRun === 10){
        clearInterval(interval);
      }
    footnoteRefs.current.forEach((el, index) => {
      const footnoteFromTop = el.offsetTop
      sidenoteRefs.current[index].style.opacity = "1"
      const previousSidenote = sidenoteRefs.current[index - 1]
      if (previousSidenote) {
        const previousSidenoteTop = parseFloat(previousSidenote.style.top)
        const previousSidenoteHeight = previousSidenote.clientHeight
        const previousSidenoteSpace = previousSidenoteTop + previousSidenoteHeight
        if (footnoteFromTop < previousSidenoteSpace) {
            sidenoteRefs.current[index].style.top = previousSidenoteSpace + 12 + "px"
        } else {
          sidenoteRefs.current[index].style.top = footnoteFromTop + "px"
        }
      } else {
        sidenoteRefs.current[index].style.top = footnoteFromTop + "px"
      }
      return footnoteFromTop
    })
    }, 1000);
  }

  const [isToggled, setToggled] = useState(true);
  const toggleTrueFalse = () => setToggled(!isToggled);

  useEffect(()=> {
    if (allFootnotes.length > 0) {
      window.addEventListener("resize", setSidenotes)
      setSidenotes();
    };
  }, [footnoteRefs, sidenoteRefs, allFootnotes])

  const currentUrl = 'https://pcen.fr/activites/' + post.slug.current

  if (allFootnotes.length > 0) {
    return (
      <Layout>
        <SEO 
        title={title}
        description={excerpt}
        image={post.mainImage.asset.url}
        url = {currentUrl}
        />
        <CoverImage style={isToggled === true ? {width: "100%"} : {width: "400%"}}>
          <GatsbyImage image={image} alt=""/>
        </CoverImage>
        <SmallCircle to="/activites">
          <ArrowSvg id="backarrow" dangerouslySetInnerHTML={{__html: pcenarrow}}/>
        </SmallCircle>
        <EmptySquare onClick={()=>{toggleTrueFalse(); setSidenotes()}}>
          <ActionPrompt id="actionprompt" context="nonblock">{isToggled === true ? '+' : '-'}</ActionPrompt>
        </EmptySquare>
        <MetadataList>
          <Date context='smalltext'>{pinned(date)}</Date>
          <li>
            <ContentAuthors>
              {authors.map((author, i) => (
                  <Li context="smalltext" key={i}>
                      {author.firstname} {author.surname}
                  </Li>
              ))}
            </ContentAuthors>
          </li>
          <li>
            <ContentType>
              {type.map((typ, i) => (
                  <MetadataElement key={i}>
                      <P context="smalltextbubble">{typ}</P>
                  </MetadataElement>
              ))}
              {sector.map((sect, i) => (
                  <MetadataElement key={i}>
                      <P context="smalltextbubble">{sect}</P>
                  </MetadataElement>
              ))}
            </ContentType>
          </li>
        </MetadataList>
        <ContentTypeMobile>
          {type.map((typ, i) => (
              <MetadataElement key={i}>
                  <P context="smalltextbubble">{typ}</P>
              </MetadataElement>
          ))}
          {sector.map((sect, i) => (
              <MetadataElement key={i}>
                  <P context="smalltextbubble">{sect}</P>
              </MetadataElement>
          ))}
        </ContentTypeMobile>
        <Title>{title}</Title>
        <Excerpt>{excerpt}</Excerpt>
        <TextBlock>
          <PortableText
            blocks={bodyElements}
            serializers = {serializer}
          />
        </TextBlock>
        {allFootnotes.map((footnote, i) => (
          <Sidenote key={footnote._key} ref={addToSidenoteRefs}>
            <SidenoteNumber context="smalltext">{footnotesOrder(footnote._key)}.</SidenoteNumber>
            <PortableText
              blocks={footnote.footnoteText}
              serializers = {FootnoteSerializer}
            />
          </Sidenote>
        ))}
        <FootnotesBottom>
          <FootnoteSectionTitle>
            <FootnoteSectionTitleText context="smalltext">Notes</FootnoteSectionTitleText>
          </FootnoteSectionTitle>
          <FootnoteList>
            {allFootnotes.map((footnote, i) => (
              <FootnoteListItem context="footnote" id={"footnote-" + footnote._key} key={footnote._key} footnotelabel={i + 1}>
                <FootnoteListItemBack href={"#footnote-back-" + footnote._key}>&uarr;</FootnoteListItemBack>
                <PortableText
                blocks={footnote.footnoteText}
                serializers = {FootnoteSerializer}
                />
              </FootnoteListItem>
            ))}
          </FootnoteList>
        </FootnotesBottom>
      </Layout>
    )
  } else {
    return (
      <Layout>
        <SEO 
        title={title}
        description={excerpt}
        image={post.mainImage.asset.url}
        url = {currentUrl}
        />
        <CoverImage style={isToggled === true ? {width: "100%"} : {width: "400%"}}>
          <GatsbyImage image={image} alt=""/>
        </CoverImage>
        <SmallCircle to="/activites">
          <ArrowSvg id="backarrow" dangerouslySetInnerHTML={{__html: pcenarrow}}/>
        </SmallCircle>
        <EmptySquare onClick={()=>{toggleTrueFalse(); setSidenotes()}}>
          <ActionPrompt id="actionprompt" context="nonblock">{isToggled === true ? '+' : '-'}</ActionPrompt>
        </EmptySquare>
        <MetadataList>
          <Date context='smalltext'>{pinned(date)}</Date>
          <li>
            <ContentAuthors>
              {authors.map((author, i) => (
                  <Li context="smalltext" key={i}>
                      {author.firstname} {author.surname}
                  </Li>
              ))}
            </ContentAuthors>
          </li>
          <li>
            <ContentType>
              {type.map((typ, i) => (
                  <MetadataElement key={i}>
                      <P context="smalltextbubble">{typ}</P>
                  </MetadataElement>
              ))}
              {sector.map((sect, i) => (
                  <MetadataElement key={i}>
                      <P context="smalltextbubble">{sect}</P>
                  </MetadataElement>
              ))}
            </ContentType>
          </li>
        </MetadataList>
        <ContentTypeMobile>
          {type.map((typ, i) => (
              <MetadataElement key={i}>
                  <P context="smalltextbubble">{typ}</P>
              </MetadataElement>
          ))}
          {sector.map((sect, i) => (
              <MetadataElement key={i}>
                  <P context="smalltextbubble">{sect}</P>
              </MetadataElement>
          ))}
        </ContentTypeMobile>
        <Title>{title}</Title>
        <Excerpt>{excerpt}</Excerpt>
        <TextBlock>
          <PortableText
            blocks={bodyElements}
            serializers = {serializer}
          />
        </TextBlock>
      </Layout>
    )
  }
}

export default SinglePost

export const pageQuery = graphql`
    query SinglePostQuery($id: String!) {
        currentpost: allSanityPost(filter: {id: { eq: $id }}) {
            edges {
              node {
                _rawBody(resolveReferences: {maxDepth: 10})
                id
                mainImage {
                  asset {
                    gatsbyImageData(width: 2000)
                    url
                  }
                }
                slug {
                  current
                }
                title
                excerpt
                publishedAt(formatString:"DD-MM-YYYY")
                authors {
                  firstname
                  surname
                }
                type
                secteur
              }
            }
        }
    }
`